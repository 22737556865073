<template>
  <div class="content md-mt-0 md-pt-0">
    <md-list :md-expand-single="true" class="md-block">
      <md-list-item md-expand>
        <md-icon>filter_alt</md-icon>
        <md-list slot="md-expand">
          <div class="md-layout">
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
            >
              <md-card>
                <md-card-header data-background-color="primary">
                  <h4 class="title">Filtro por localidades</h4>
                </md-card-header>
                <md-card-content
                  class="md-m-0 md-p-0"
                  v-for="(state, index) in statesSearched"
                  :key="index"
                >
                  <md-table
                    class="md-dense"
                    md-sort="name"
                    md-sort-order="asc"
                    v-model="state.cities"
                    md-card
                    md-fixed-header
                  >
                    <md-table-toolbar>
                      <div class="md-toolbar-section-start">
                        <h1 class="md-title">
                          Localidades do {{ state.name }}
                        </h1>
                        <md-checkbox v-model="scope_location" value="codmunres">
                          Residência
                        </md-checkbox>
                        <md-checkbox
                          v-model="scope_location"
                          value="codmunocor"
                        >
                          Ocorrência
                        </md-checkbox>
                      </div>
                      <div class="md-toolbar-section-end">
                        <md-field md-clearable>
                          <md-input
                            v-model="termSearch"
                            @click.stop="
                              () => {
                                return true;
                              }
                            "
                            @input="
                              searchByName(
                                states[index].cities,
                                termSearch,
                                index
                              )
                            "
                            placeholder="Buscar..."
                          />
                        </md-field>
                        <md-button
                          class="md-raised md-danger"
                          @click="clearSelect"
                          >Limpar</md-button
                        >
                      </div>
                    </md-table-toolbar>

                    <md-table-empty-state
                      md-label="Não localizado"
                      :md-description="
                        `não foi possivel encontar esta localidade '${'text'}'.`
                      "
                    >
                    </md-table-empty-state>
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
                      <md-table-cell md-label="Cidades" md-sort-by="name">
                        <md-checkbox
                          v-model="citySelected"
                          :value="item.id"
                          @change="update('city')"
                        />
                        {{ item.name }}
                      </md-table-cell>
                      <md-table-cell
                        md-label="Microrregiões"
                        md-sort-by="microregion_sesapi.name"
                      >
                        <md-checkbox
                          v-model="microregionSelected"
                          :value="item.microregion_sesapi.id"
                          @change="update('microregion')"
                        />
                        {{ item.microregion_sesapi.name }}
                      </md-table-cell>
                      <md-table-cell
                        md-label="Mesorregiões"
                        md-sort-by="mesoregion_sesapi.name"
                      >
                        <md-checkbox
                          v-model="mesoregionSelected"
                          :value="item.mesoregion_sesapi.id"
                          @change="update('mesoregion')"
                        />
                        {{ item.mesoregion_sesapi.name }}</md-table-cell
                      >
                    </md-table-row>
                  </md-table>
                </md-card-content>
              </md-card>
            </div>
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
            >
              <filter-cid-card @update="setCids"></filter-cid-card>˝
            </div>
          </div>
        </md-list>
      </md-list-item>
    </md-list>

    <div class="md-layout">
      <div
        v-for="item in dataSets"
        :key="item.id"
        class="md-layout-item md-xsmall-size-100 md-size-20"
      >
        <stats-card>
          <template slot="header">
            <div
              class="card-icon md-elevation-5"
              style="margin-right: auto;"
              :style="
                `background: linear-gradient(60deg, rgba(153, 153, 153, 0.5) 1%, ${item.color} 95%)`
              "
            >
              <router-link
                v-if="location_type"
                :to="
                  `/mosaico/sim/details/${item.source}/${item.name}/${item.id}/${location_type}/${location_id}/${scope_location}`
                "
              >
                <md-icon>dashboard</md-icon>
              </router-link>
              <router-link
                v-else
                :to="
                  `/mosaico/sim/details/${item.source}/${item.name}/${item.id}/`
                "
              >
                <md-icon>dashboard</md-icon>
              </router-link>
            </div>
            <p class="category">
              Total Óbitos" {{ item.total | locateNumber }}
            </p>
            <router-link
              v-if="location_type"
              :to="
                `/mosaico/sim/details/${item.source}/${item.name}/${item.id}/${location_type}/${location_id}/${scope_location}`
              "
            >
              <h3 class="title">
                {{ item.year }}
              </h3>
            </router-link>
            <router-link
              v-else
              :to="
                `/mosaico/sim/details/${item.source}/${item.name}/${item.id}/`
              "
            >
              <h3 class="title">
                {{ item.year }}
              </h3>
            </router-link>
          </template>
          <template slot="content">
            <md-button
              v-if="location_type"
              class="md-raised md-info md-m-1 md-block"
              :to="
                `/mosaico/sim/details/${item.source}/${item.name}/${item.id}/${location_type}/${location_id}/${scope_location}`
              "
            >
              Detalhes
            </md-button>
            <md-button
              v-else
              class="md-raised md-info md-m-1 md-block"
              :to="
                `/mosaico/sim/details/${item.source}/${item.name}/${item.id}/`
              "
            >
              Detalhes
            </md-button>
            <md-button
              v-if="location_type"
              class="md-raised md-danger md-m-1 md-block"
              :to="
                `/mosaico/sim/map/${item.source}/${item.name}/${item.id}/${location_type}/${location_id}/${scope_location}`
              "
            >
              <md-icon>map</md-icon>
              Mapa de óbitos
            </md-button>
            <md-button
              v-else
              class="md-raised md-danger md-m-1 md-block"
              :to="`/mosaico/sim/map/${item.source}/${item.name}/${item.id}/`"
            >
              <md-icon>map</md-icon>
              Mapa de Óbitos
            </md-button>
          </template>
          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Atualizado em: {{ item.updated_at | formatDate }}
            </div>
          </template>
        </stats-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-sim-value">
                <apexchart
                  type="line"
                  height="350"
                  :options="optionsLinesDoByMonth"
                  :series="seriesLinesDoByMonth"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSets.length > 0">
              Daclaração de óbitos de,
              <span class="text-success">
                {{ dataSets[dataSets.length - 1].year }} -
                {{ dataSets[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-bar">
                <apexchart
                  type="bar"
                  height="350"
                  :options="optionsBar"
                  :series="seriesBar"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSets.length > 0">
              Daclaração de óbitos de,
              <span class="text-success">
                {{ dataSets[dataSets.length - 1].year }} -
                {{ dataSets[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-sim-value">
                <apexchart
                  type="line"
                  height="350"
                  :options="optionsLinesDoMatByMonth"
                  :series="seriesLinesDoMatByMonth"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSetsMat.length > 0">
              Daclaração de óbitos materno de,
              <span class="text-success">
                {{ dataSetsMat[dataSetsMat.length - 1].year }} -
                {{ dataSetsMat[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-bar">
                <apexchart
                  type="bar"
                  height="350"
                  :options="optionsBarMat"
                  :series="seriesBarMat"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSetsMat.length > 0">
              Daclaração de óbitos materno de,
              <span class="text-success">
                {{ dataSetsMat[dataSetsMat.length - 1].year }} -
                {{ dataSetsMat[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-sim-value">
                <apexchart
                  type="line"
                  height="350"
                  :options="optionsLinesDoFetByMonth"
                  :series="seriesLinesDoFetByMonth"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSetsFet.length > 0">
              Daclaração de óbitos fetais de,
              <span class="text-success">
                {{ dataSetsFet[dataSetsFet.length - 1].year }} -
                {{ dataSetsFet[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-bar">
                <apexchart
                  type="bar"
                  height="350"
                  :options="optionsBarFet"
                  :series="seriesBarFet"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSetsFet.length > 0">
              Daclaração de óbitos fetais de,
              <span class="text-success">
                {{ dataSetsFet[dataSetsFet.length - 1].year }} -
                {{ dataSetsFet[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-sim-value">
                <apexchart
                  type="line"
                  height="350"
                  :options="optionsLinesDoInfByMonth"
                  :series="seriesLinesDoInfByMonth"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSetsInf.length > 0">
              Daclaração de óbitos infantis de,
              <span class="text-success">
                {{ dataSetsInf[dataSetsInf.length - 1].year }} -
                {{ dataSetsInf[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-bar">
                <apexchart
                  type="bar"
                  height="350"
                  :options="optionsBarInf"
                  :series="seriesBarInf"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSetsInf.length > 0">
              Daclaração de óbitos infantis de,
              <span class="text-success">
                {{ dataSetsInf[dataSetsInf.length - 1].year }} -
                {{ dataSetsInf[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-sim-value">
                <apexchart
                  type="line"
                  height="350"
                  :options="optionsLinesDoExtByMonth"
                  :series="seriesLinesDoExtByMonth"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSetsExt.length > 0">
              Daclaração de óbitos Prováveis Circunstâncias de Morte não
              naturalde,
              <span class="text-success">
                {{ dataSetsExt[dataSetsExt.length - 1].year }} -
                {{ dataSetsExt[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-bar">
                <apexchart
                  type="bar"
                  height="350"
                  :options="optionsBarExt"
                  :series="seriesBarExt"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSetsExt.length > 0">
              Daclaração de óbitos Prováveis Circunstâncias de Morte não natural
              de,
              <span class="text-success">
                {{ dataSetsExt[dataSetsExt.length - 1].year }} -
                {{ dataSetsExt[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { StatsCard, FilterCidCard } from "@/components";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    StatsCard,
    apexchart: VueApexCharts,
    FilterCidCard
  },
  data() {
    return {
      selectedCids: [],
      selectedChaptersCids: [],
      selectedGroupsCids: [],
      termSearch: "",
      statesSearched: [],
      location_type: this.$store.state.sim.location_type,
      location_id: this.$store.state.sim.location_id,
      states: [],
      dataSets: [],
      dataSetsMat: [],
      dataSetsFet: [],
      dataSetsInf: [],
      dataSetsExt: [],
      scope_location: "codmunocor",
      mesoregionSelected: null,
      mesoregionsSeries: [],
      microregionSelected: null,
      microregionsSeries: [],
      citySelected: null,
      citiesSeries: [],
      seriesLinesDoByMonth: [],
      optionsLinesDoByMonth: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Ocorrência de óbitos por mês",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        yaxis: {
          style: {
            fontSize: "9px",
            fontWeight: 100
          },
          labels: {}
        },
        tooltip: {
          y: {}
        }
      },
      seriesBar: [],
      optionsBar: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function(chart, w, e) {
              console.log(chart, w, e);
            }
          },
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          style: {
            fontSize: "9px"
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.7
          }
        },
        title: {
          text: "Ocorrência de óbitos por ano",
          align: "left"
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          type: "numeric",
          categories: [],
          tickAmount: "dataPoints",
          tickPlacement: "on",
          labels: {
            formatter: function(value) {
              if (value != undefined) {
                return value.toFixed(0);
              }
            }
          }
        }
      },
      seriesLinesDoMatByMonth: [],
      optionsLinesDoMatByMonth: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Ocorrência de óbitos MATERNOS por mês",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        yaxis: {
          style: {
            fontSize: "9px",
            fontWeight: 100
          },
          labels: {}
        },
        tooltip: {
          y: {}
        }
      },
      seriesBarMat: [],
      optionsBarMat: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function(chart, w, e) {
              console.log(chart, w, e);
            }
          },
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          style: {
            fontSize: "9px"
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.7
          }
        },
        title: {
          text: "Ocorrência de óbitos MATERNOS por ano",
          align: "left"
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          type: "numeric",
          categories: [],
          tickAmount: "dataPoints",
          tickPlacement: "on",
          labels: {
            formatter: function(value) {
              if (value != undefined) {
                return value.toFixed(0);
              }
            }
          }
        }
      },
      seriesLinesDoFetByMonth: [],
      optionsLinesDoFetByMonth: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Ocorrência de óbitos FETAIS por mês",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        yaxis: {
          style: {
            fontSize: "9px",
            fontWeight: 100
          },
          labels: {}
        },
        tooltip: {
          y: {}
        }
      },
      seriesBarFet: [],
      optionsBarFet: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function(chart, w, e) {
              console.log(chart, w, e);
            }
          },
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          style: {
            fontSize: "9px"
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.7
          }
        },
        title: {
          text: "Ocorrência de óbitos FETAIS por ano",
          align: "left"
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          type: "numeric",
          categories: [],
          tickAmount: "dataPoints",
          tickPlacement: "on",
          labels: {
            formatter: function(value) {
              if (value != undefined) {
                return value.toFixed(0);
              }
            }
          }
        }
      },
      seriesLinesDoInfByMonth: [],
      optionsLinesDoInfByMonth: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Ocorrência de óbitos INFANTIS por mês",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        yaxis: {
          style: {
            fontSize: "9px",
            fontWeight: 100
          },
          labels: {}
        },
        tooltip: {
          y: {}
        }
      },
      seriesBarInf: [],
      optionsBarInf: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function(chart, w, e) {
              console.log(chart, w, e);
            }
          },
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          style: {
            fontSize: "9px"
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.7
          }
        },
        title: {
          text: "Ocorrência de óbitos INFANTIS por ano",
          align: "left"
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          type: "numeric",
          categories: [],
          tickAmount: "dataPoints",
          tickPlacement: "on",
          labels: {
            formatter: function(value) {
              if (value != undefined) {
                return value.toFixed(0);
              }
            }
          }
        }
      },
      seriesLinesDoExtByMonth: [],
      optionsLinesDoExtByMonth: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text:
            "Ocorrência de óbitos prováveis circunstâncias de morte não natural por mês",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        yaxis: {
          style: {
            fontSize: "9px",
            fontWeight: 100
          },
          labels: {}
        },
        tooltip: {
          y: {}
        }
      },
      seriesBarExt: [],
      optionsBarExt: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function(chart, w, e) {
              console.log(chart, w, e);
            }
          },
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          style: {
            fontSize: "9px"
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.7
          }
        },
        title: {
          text:
            "Ocorrência de óbitos prováveis circunstâncias de morte não natural por ano",
          align: "left"
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          type: "numeric",
          categories: [],
          tickAmount: "dataPoints",
          tickPlacement: "on",
          labels: {
            formatter: function(value) {
              if (value != undefined) {
                return value.toFixed(0);
              }
            }
          }
        }
      }
    };
  },
  watch: {
    scope_location: function(val, oldVal) {
      console.log(val, oldVal);
      if (val != oldVal) {
        this.getAllDataSeries();
      }
    }
  },
  async created() {
    if (this.$store.state.sim.scope_location != null) {
      this.scope_location = this.$store.state.sim.scope_location;
    }
    this.location_type = this.$store.state.sim.location_type;
    this.location_id = this.$store.state.sim.location_id;
  },
  computed: {
    loggedIn: function() {
      return this.$store.state.auth.status.loggedIn;
    },
    user: function() {
      return this.$store.state.auth.user;
    }
  },
  async mounted() {
    await this.welcomeMessage();
    this.getAllDataSeries();
  },
  props: {},
  methods: {
    async getAllDataSeries() {
      await this.getDataSets();
      this.getStates();
      this.getSerieBar();
      this.getSerieBarMat();
      this.getSerieBarFet();
      this.getSerieBarInf();
      this.getSerieBarExt();
      this.getSerieDoByMonth();
      this.getSerieDoMatByMonth();
      this.getSerieDoFetByMonth();
      this.getSerieDoInfByMonth();
      this.getSerieDoExtByMonth();
    },
    setFilters(params) {
      if (this.location_type == null) {
        params = this.setFiltersCids(params);
      } else {
        params.location_type = this.location_type;
        params.location_id = this.location_id;

        params = this.setFiltersCids(params);
      }
      return params;
    },
    setFiltersCids(params) {
      if (this.selectedCids.length > 0) {
        let filters_cid = [];
        for (const cid of this.selectedCids) {
          filters_cid.push(cid.code);
        }
        params.column_filters = "causabas";
        params.term_filters = filters_cid;
      }

      if (this.selectedChaptersCids.length > 0) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }

        for (const chapterCid of this.selectedChaptersCids) {
          params.column_filter_or.push("causabas");
          params.term_filter_or_range.push([
            chapterCid.starting_code,
            chapterCid.final_code
          ]);
        }
      }

      if (this.selectedGroupsCids.length > 0) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }
        for (const groupCid of this.selectedGroupsCids) {
          params.column_filter_or.push("causabas");
          params.term_filter_or_range.push([
            groupCid.starting_code,
            groupCid.final_code
          ]);
        }
      }

      return params;
    },
    welcomeMessage() {
      this.$emit("welcomeMessage", {
        title: "Dados de SIM"
      });
    },
    async getDataSets() {
      try {
        let result;
        let result_mat;
        let result_fet;
        let result_inf;
        let result_ext;

        let params = {};
        params = this.setFilters(params);
        result = await Vue.$http.get(`v1/dataset/consume/datasus/sim/`, {
          params: params
        });
        this.dataSets = await result.data;

        result_mat = await Vue.$http.get(
          `v1/dataset/consume/datasus/sim_mat/`,
          {
            params: params
          }
        );
        this.dataSetsMat = await result_mat.data;

        result_fet = await Vue.$http.get(
          `v1/dataset/consume/datasus/sim_fet/`,
          {
            params: params
          }
        );
        this.dataSetsFet = await result_fet.data;

        result_inf = await Vue.$http.get(
          `v1/dataset/consume/datasus/sim_inf/`,
          {
            params: params
          }
        );
        this.dataSetsInf = await result_inf.data;

        result_ext = await Vue.$http.get(
          `v1/dataset/consume/datasus/sim_ext/`,
          {
            params: params
          }
        );
        this.dataSetsExt = await result_ext.data;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async getSerieDoByMonth() {
      let result;
      if (this.seriesLinesDoByMonth.length > 0) {
        this.seriesLinesDoByMonth = [];
        this.optionsLinesDoByMonth.colors = [];
      }

      let params = {
        per: "dtobito",
        rating: "dtobito",
        operation: "count",
        per_page: 12,
        page: 1
      };

      params = this.setFilters(params);

      for (const item of this.dataSets) {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sim/${item.id}/`,
          {
            params: params
          }
        );

        this.optionsLinesDoByMonth.colors.push(item.color);
        let data = [];
        result.data.data.forEach(async itemData => {
          data.push(itemData.count);
        });
        this.seriesLinesDoByMonth.push({
          name: `${item.year}: ${item.location_name}`,
          data: data
        });
      }
    },
    async getSerieDoMatByMonth() {
      let result;
      if (this.seriesLinesDoMatByMonth.length > 0) {
        this.seriesLinesDoMatByMonth = [];
        this.optionsLinesDoMatByMonth.colors = [];
      }

      let params = {
        per: "dtobito",
        rating: "dtobito",
        operation: "count",
        per_page: 12,
        page: 1
      };

      params = this.setFilters(params);

      for (const item of this.dataSetsMat) {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sim_mat/${item.id}/`,
          {
            params: params
          }
        );

        this.optionsLinesDoMatByMonth.colors.push(item.color);
        let data = [];
        result.data.data.forEach(async itemData => {
          data.push(itemData.count);
        });
        this.seriesLinesDoMatByMonth.push({
          name: `${item.year}: ${item.location_name}`,
          data: data
        });
      }
    },
    async getSerieDoFetByMonth() {
      let result;
      if (this.seriesLinesDoFetByMonth.length > 0) {
        this.seriesLinesDoFetByMonth = [];
        this.optionsLinesDoFetByMonth.colors = [];
      }

      let params = {
        per: "dtobito",
        rating: "dtobito",
        operation: "count",
        per_page: 12,
        page: 1
      };

      params = this.setFilters(params);

      for (const item of this.dataSetsFet) {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sim_fet/${item.id}/`,
          {
            params: params
          }
        );

        this.optionsLinesDoFetByMonth.colors.push(item.color);
        let data = [];
        result.data.data.forEach(async itemData => {
          data.push(itemData.count);
        });
        this.seriesLinesDoFetByMonth.push({
          name: `${item.year}: ${item.location_name}`,
          data: data
        });
      }
    },
    async getSerieDoInfByMonth() {
      let result;
      if (this.seriesLinesDoInfByMonth.length > 0) {
        this.seriesLinesDoInfByMonth = [];
        this.optionsLinesDoInfByMonth.colors = [];
      }

      let params = {
        per: "dtobito",
        rating: "dtobito",
        operation: "count",
        per_page: 12,
        page: 1
      };

      params = this.setFilters(params);

      for (const item of this.dataSetsInf) {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sim_inf/${item.id}/`,
          {
            params: params
          }
        );

        this.optionsLinesDoInfByMonth.colors.push(item.color);
        let data = [];
        result.data.data.forEach(async itemData => {
          data.push(itemData.count);
        });
        this.seriesLinesDoInfByMonth.push({
          name: `${item.year}: ${item.location_name}`,
          data: data
        });
      }
    },
    async getSerieDoExtByMonth() {
      let result;
      if (this.seriesLinesDoExtByMonth.length > 0) {
        this.seriesLinesDoExtByMonth = [];
        this.optionsLinesDoExtByMonth.colors = [];
      }

      let params = {
        per: "dtobito",
        rating: "dtobito",
        operation: "count",
        per_page: 12,
        page: 1
      };

      params = this.setFilters(params);

      for (const item of this.dataSetsExt) {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sim_ext/${item.id}/`,
          {
            params: params
          }
        );

        this.optionsLinesDoExtByMonth.colors.push(item.color);
        let data = [];
        result.data.data.forEach(async itemData => {
          data.push(itemData.count);
        });
        this.seriesLinesDoExtByMonth.push({
          name: `${item.year}: ${item.location_name}`,
          data: data
        });
      }
    },
    async getSerieBar() {
      if (this.seriesBar.length > 0) {
        this.seriesBar = [];
        this.optionsBar.xaxis.categories = [];
        this.optionsLinesDoByMonth.colors = [];
      }

      this.seriesBar.push({
        name: `Óbitos: ${this.dataSets[0].location_name}`,
        data: []
      });

      for (const item of this.dataSets) {
        this.optionsBar.colors.push(item.color);
        this.optionsBar.xaxis.categories.push(item.year);
        this.seriesBar[0].data.push(item.total);
      }
    },
    async getSerieBarMat() {
      if (this.seriesBarMat.length > 0) {
        this.seriesBarMat = [];
        this.optionsBarMat.xaxis.categories = [];
        this.optionsLinesDoMatByMonth.colors = [];
      }

      this.seriesBarMat.push({
        name: `Óbitos: ${this.dataSetsMat[0].location_name}`,
        data: []
      });

      await this.dataSetsMat.forEach(item => {
        this.optionsBarMat.colors.push(item.color);
        this.optionsBarMat.xaxis.categories.push(item.year);
        this.seriesBarMat[0].data.push(item.total);
      });
    },
    async getSerieBarFet() {
      if (this.seriesBarFet.length > 0) {
        this.seriesBarFet = [];
        this.optionsBarFet.xaxis.categories = [];
        this.optionsLinesDoFetByMonth.colors = [];
      }

      this.seriesBarFet.push({
        name: `Óbitos: ${this.dataSetsFet[0].location_name}`,
        data: []
      });

      await this.dataSetsFet.forEach(item => {
        this.optionsBarFet.colors.push(item.color);
        this.optionsBarFet.xaxis.categories.push(item.year);
        this.seriesBarFet[0].data.push(item.total);
      });
    },
    async getSerieBarInf() {
      if (this.seriesBarInf.length > 0) {
        this.seriesBarInf = [];
        this.optionsBarInf.xaxis.categories = [];
        this.optionsLinesDoInfByMonth.colors = [];
      }

      this.seriesBarInf.push({
        name: `Óbitos: ${this.dataSetsInf[0].location_name}`,
        data: []
      });

      await this.dataSetsInf.forEach(item => {
        this.optionsBarInf.colors.push(item.color);
        this.optionsBarInf.xaxis.categories.push(item.year);
        this.seriesBarInf[0].data.push(item.total);
      });
    },
    async getSerieBarExt() {
      if (this.seriesBarExt.length > 0) {
        this.seriesBarExt = [];
        this.optionsBarExt.xaxis.categories = [];
        this.optionsLinesDoExtByMonth.colors = [];
      }

      this.seriesBarExt.push({
        name: `Óbitos: ${this.dataSetsExt[0].location_name}`,
        data: []
      });

      await this.dataSetsExt.forEach(item => {
        this.optionsBarExt.colors.push(item.color);
        this.optionsBarExt.xaxis.categories.push(item.year);
        this.seriesBarExt[0].data.push(item.total);
      });
    },
    async getStates() {
      this.states = [];
      this.statesSearched = [];

      try {
        let result = await Vue.$http.get(`v1/city?type=detail&level=sesapi`);

        this.states = result.data;
        this.statesSearched = JSON.parse(JSON.stringify(this.states));
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    toLower(text) {
      text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return text.toString().toLowerCase();
    },
    searchByName(items, term, index) {
      if (term) {
        items = items.filter(item => {
          if (this.toLower(item.name).includes(this.toLower(term))) {
            return true;
          } else if (
            this.toLower(item.microregion_sesapi.name).includes(
              this.toLower(term)
            )
          ) {
            return true;
          } else if (
            this.toLower(item.mesoregion_sesapi.name).includes(
              this.toLower(term)
            )
          ) {
            return true;
          }
          return false;
        });
      }
      this.statesSearched[index].cities = items;
    },
    async clearSelect() {
      this.mesoregionSelected = null;
      this.microregionSelected = null;
      this.citySelected = null;
      this.location_type = null;
      this.location_id = null;
      this.$store.commit("sim/setLocation", {
        location_type: this.location_type,
        location_id: this.location_id
      });
      this.getAllDataSeries();
    },
    setCids(selectedCids) {
      if (selectedCids.type == "cids") {
        this.selectedCids = [...selectedCids.data];
        this.updateCids();
      } else if (selectedCids.type == "chaptersCids") {
        this.selectedChaptersCids = [...selectedCids.data];
        this.updateCids();
      } else if (selectedCids.type == "groupsCids") {
        this.selectedGroupsCids = [...selectedCids.data];
        this.updateCids();
      }
    },
    updateCids() {
      if (this.selectedCids.length > 0) {
        let filter_cids = [];
        for (const cid of this.selectedCids) {
          filter_cids.push(cid.code);
        }
        this.$store.commit("sim/setCids", {
          filter_cids: filter_cids
        });
      } else {
        this.$store.commit("sim/setCids", {
          filter_cids: null
        });
      }

      if (this.selectedChaptersCids.length > 0) {
        let filter_chapters_cids = [];
        for (const chapterCid of this.selectedChaptersCids) {
          filter_chapters_cids.push([
            chapterCid.starting_code,
            chapterCid.final_code
          ]);
        }
        this.$store.commit("sim/setChaptersCids", {
          filter_chapters_cids: filter_chapters_cids
        });
      } else {
        this.$store.commit("sim/setChaptersCids", {
          filter_chapters_cids: null
        });
      }

      if (this.selectedGroupsCids.length > 0) {
        let filter_groups_cids = [];
        for (const groupCid of this.selectedGroupsCids) {
          filter_groups_cids.push([
            groupCid.starting_code,
            groupCid.final_code
          ]);
        }
        console.log(filter_groups_cids);
        this.$store.commit("sim/setGroupsCids", {
          filter_groups_cids: filter_groups_cids
        });
      } else {
        this.$store.commit("sim/setGroupsCids", {
          filter_groups_cids: null
        });
      }
      this.getAllDataSeries();
    },
    async updateLocation(location_type = null) {
      if (location_type == "city") {
        this.mesoregionSelected = null;
        this.microregionSelected = null;

        this.location_type = location_type;
        this.location_id = this.citySelected;

        if (this.location_id == null) {
          this.location_type = null;
        }

        this.getAllDataSeries();
      } else if (location_type == "microregion") {
        this.mesoregionSelected = null;
        this.citySelected = null;

        this.location_type = location_type;
        this.location_id = this.microregionSelected;

        if (this.location_id == null) {
          this.location_type = null;
        }

        this.getAllDataSeries();
      } else if (location_type == "mesoregion") {
        this.microregionSelected = null;
        this.citySelected = null;

        this.location_type = location_type;
        this.location_id = this.mesoregionSelected;

        if (this.location_id == null) {
          this.location_type = null;
        }

        this.getAllDataSeries();
      } else {
        this.getAllDataSeries();
      }
      this.$store.commit("sim/setLocation", {
        location_type: this.location_type,
        location_id: this.location_id
      });
      return true;
    }
  }
};
</script>
<style lang="scss"></style>
